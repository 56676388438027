<template>
  <div class="main-wrapper">
    <el-scrollbar>
      <div class="quality-course-content">
        <div class="content-item-left">
          <div class="content-left">
            <p style="margin-top: 0">
              <span class="border"></span><span>商品基本信息</span>
            </p>
            <el-form
              :model="createCourseForm"
              label-width="150px"
              :rules="rules"
              ref="createCourseForm"
              class="demo-ruleForm"
            >
              <el-form-item label="商品标题" prop="name" class="other-item">
                <el-input ref="name" v-model="createCourseForm.name"   placeholder="2-200个字"></el-input>
              </el-form-item>
              <el-form-item
                label="日常价（元）"
                prop="price"
                class="other-item"
              >
                <el-input-number
                  ref="name"
                  :controls="false"
                  :min="0.1"
                  :precision="2"
                  placeholder="请输入日常价"
                  v-model="createCourseForm.price"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="商品sku" prop="sku" class="other-item">
                <el-input ref="name" min="2" max="16" v-model="createCourseForm.sku" placeholder="2-16个字"></el-input>
              </el-form-item>
              <el-form-item label="直播价（元）" prop="live" class="other-item">
                <el-input-number
                  ref="name"
                  :controls="false"
                  :min="0.1"
                  :precision="2"
                   :max="9999999"
                  v-model="createCourseForm.live"
                  placeholder="请输入直播价"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                label="佣金比例（利润率）"
                prop="commission"
                class="other-item"
              >
                <el-input-number
                  :controls="false"
                  :precision="2"
                  :step="0.01"
                  :min="0.01"
                  :max="100.0"
                  ref="name"
                   placeholder="0.01-100"
                  v-model="createCourseForm.commission"
                ></el-input-number>
                <span style="padding-left: 10px;">%</span>
              </el-form-item>
              <el-form-item label="商品描述" class="other-item">
                <vue-tinymce
                  ref="tiny_item1"
                  :setting="setting"
            
                  v-model="subjectForm.describe"
                  :key="tinymceFlag"
                ></vue-tinymce>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="content-item-right">
          <div class="content-right">
            <p style="margin-top: 0">
              <span class="border"></span><span>商品图片</span>
            </p>
            <div class="cont">
              <p style="margin-top: 0"><span>商品展示图</span></p>
              <div class="img-arr">
                <draggable
                  v-model="imgArr"
                  filter=".forbid"
                  chosen-class="chosen"
                  force-fallback="true"
                  group="people"
                  animation="1000"
                  @start="onStart"
                  @end="onEnd"
                  :move="onMove"
                >
                  <transition-group
                    style="flex: 1; display: flex; flex-wrap: wrap"
                  >
                    <div
                      class="log-img"
                      :class="item.id == 0 ? 'item forbid' : 'item'"
                      v-for="(item, index) in imgArr"
                      :key="item.id"
                    >
                      <div class="logo-src-box" v-if="item.avatar">
                        <img :src="item.logoSrc" alt="" />

                        <span @click.stop.prevent="clearLogo(item)" class="close"
                          ><i class="">×</i></span
                        >
                      </div>

                      <el-upload
                        class="logo-uploader"
                        v-else
                        action="api/admin/goods/goodsUpload"
                        name="goods_img"
                        :headers="headersParams"
                        :show-file-list="false"
                        :on-success.stop.prevent="(res) => handleCoverSuccess(res, index)"
                        :before-upload="beforeCoverUpload"
                      >
                        <i class="el-icon-plus logo-uploader-icon"></i>
                      </el-upload>
                      <div class="imgName">{{ item.name }}</div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
              <p style="margin-top: 0; font-size: 0.8rem">
                <span
                  >（图片至少上传5张图，尺寸为800px*800px，仅支持jpg、jpeg、png格式，您可以拖拽图片调整顺序）</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-button">
        <el-button type="primary" @click="backTo">上一步</el-button>
        <el-button type="primary" @click="next">下一步</el-button>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { theoryTopicUploadRtImg, validateGoodsInfo } from "@/utils/apis.js";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      drag: false,
      subjectForm: {
        title: "",
        describe: "",
      },
      createCourseForm: {
        name: "", //商品标题
        price: void 0, //日常价
        sku: "", //商品SKU
        live: void 0, //直播
        commission: void 0, //佣金
        describe: "", //商品描述
      },

      hostUrl: "https://kuajing.e-class.me/",
      //主图
      masterForm: {
        avatar: "",
      },

      imgList: [],

      imgArr: [
        { id: 0, logoSrc: "", avatar: "", name: "主图" },
        {
          id: 1,
          logoSrc: "",
          avatar: "",
          name: "细节图",
        },
        {
          id: 2,
          logoSrc: "",
          avatar: "",
          name: "细节图",
        },
        {
          id: 3,
          logoSrc: "",
          avatar: "",
          name: "细节图",
        },
        {
          id: 4,
          logoSrc: "",
          avatar: "",
          name: "细节图",
        },
        {
          id: 5,
          logoSrc: "",
          avatar: "",
          name: "细节图",
        },
        {
          id: 6,
          logoSrc: "",
          avatar: "",
          name: "细节图",
        },
        {
          id: 7,
          logoSrc: "",
          avatar: "",
          name: "细节图",
        },
        {
          id: 8,
          logoSrc: "",
          avatar: "",
          name: "细节图",
        },
        {
          id: 9,
          logoSrc: "",
          avatar: "",
          name: "细节图",
        },
      ],
      goods_class_id: "",
      pasteravatar: "",
      pasterlogoSrc: "",
      sum_id:'',

      logoSrc: "",
      headersParams: {
        Authorization: localStorage.getItem("adminToken") || "",
      },
   
      setting: {
        menubar: false,
          content_style: 'img{width:100%;height:auto;}',
        // axupimgs
        plugins:
          "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
        toolbar: [
          "undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|",
          "image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|",
        ],
        content_style: 'img{width:100%;height:auto;}',
        language: "zh_CN", //本地化设置
        height: 850,

        //上传图片
        images_upload_handler: (blobInfo, success, failure, progress) => {
          let formData = new FormData();
          formData.append("goods_img", blobInfo.blob());
          theoryTopicUploadRtImg(formData)
            .then((res) => {
              this.$message.success(res.msg);
              success(res.data.url);
            })
            .catch((err) => {
              if (document.querySelector(".tox-dialog__busy-spinner")) {
                // 单图片上传
                document.querySelector(
                  ".tox-dialog__busy-spinner"
                ).style.display = "none";
                // this.$message.warning('上传失败1')
              } else {
                // 多图片上传
                failure("上传失败2");
              }
            });
        },
      },
      tinymceFlag:1,
      rules: {
        name: [
          { required: true, message: "请输入商品标题", trigger: "blur" },
          { min: 2, max: 200, message: "长度在2到200个字符", trigger: "blur" },
        ],
        price: [{ type:'number', required: true, message: "日常价必须为数字", trigger: "blur" }],
        sku: [
          { required: true, message: "请输入商品SKU", trigger: "blur" },
          { min: 2, max: 16, message: "长度在2到16个字符", trigger: "blur" },
        ],
        live: [{type:'number', required: true, message: "直播价必须为数字", trigger: "blur" }],
        commission: [
          { required: true, message: "请输入佣金比例", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    this.getquery();
    this.editEstablish()
  },
  //监听route是否变化
  // watch: {
  //   $route() {
  //     this.getquery();
  //   },
  // },
  activated(){
    this.tinymceFlag++
  },
  methods: {
    //获取普通发品页面得值
    getquery() {
      this.goods_class_id = localStorage.getItem("oneid");
    },

    // 返回上一步
    backTo() {
      if(this.$route.query.id){
         this.$router.push({
          path: "/liveStreaming/commodity-establish", query: { id: this.$route.query.id }
        });
      }else{
        this.$router.push({
          path: "/liveStreaming/commodity-establish",query: { class_id1: this.$route.query.class_id1,class_id2:this.$route.query.class_id2 }
        });
      }
     
    },

    //编辑
    editEstablish(){
      if (this.$route.query.id) {   
        this.sum_id = this.$route.query.id
        //  console.log(JSON.parse(localStorage.getItem("commodityeditData")),"有ID")
         let eiitList = JSON.parse(localStorage.getItem("commodityeditData"))
 
         this.createCourseForm.name = eiitList.goods_info.goods_name //商品标题
         this.createCourseForm.price = eiitList.goods_info.goods_market_price //日常价
         this.createCourseForm.sku = eiitList.goods_info.goods_sku //商品sku
         this.createCourseForm.live = eiitList.goods_info.goods_discount_price //直播间价格
         this.createCourseForm.commission = eiitList.goods_info.goods_commission_rate //佣金
         this.subjectForm.describe = eiitList.goods_info.goods_body //编辑器

         for(let i=0;i<eiitList.goods_info.goods_class_img.length;i++){
           this.imgArr[i].avatar = eiitList.goods_info.goods_class_img[i]
           this.imgArr[i].logoSrc = this.hostUrl +eiitList.goods_info.goods_class_img[i]
           this.imgArr[i].id = i
         }

      }
    },

    //下一步
    next() {
      this.$refs["createCourseForm"].validate((valid) => {
        if (valid) {
          let k = 0; //计算图片数组为空的个数
          for (let i = 0; i < this.imgArr.length; i++) {
            this.imgList[i] = this.imgArr[i].avatar;
            if (this.imgArr[i].avatar == "") {
              k++;
            }
          }
          //判断图片数据是否大于5张
          if (k <= 5) {
            let obj = {
              goods_class_id: this.goods_class_id, //上个页面传过来得ID
              goods_name: this.createCourseForm.name, //商品名称
              goods_market_price: this.createCourseForm.price, //日常价
              goods_sku: this.createCourseForm.sku, //商品SKU
              goods_discount_price: this.createCourseForm.live, //直播间价
              goods_commission_rate: this.createCourseForm.commission, //佣金
              goods_class_img: this.imgList, //上传图片
              goods_body: this.subjectForm.describe, //编辑器
            };
            let params = {
              id : this.sum_id,
              goods_info: obj,
            };
            //验证页面请求
            validateGoodsInfo(params).then((res) => {
              if (res.code == 200) {

                localStorage.setItem("message", JSON.stringify(obj));
                if (this.$route.query.id) {
                  this.$router.push({
                    path: "/liveStreaming/newScriptManagementData", query: { id: this.$route.query.id }
                  });
                  this.$bus.$emit("title");
                }else{
                  this.$router.push({
                    path: "/liveStreaming/newScriptManagementData",
                  });
                  this.$bus.$emit("title");
                }
               
              }
            }).catch((err) => {
              console.error("err", err);
            });
          } else {
            this.$message.warning("商品图片请至少上传5张");
          }
        }
      });
    },

    // 贴片删除头像
    pasterClearLogo() {
      this.pasterlogoSrc = null;
      this.pasteravatar = "";
    },

    // 删除头像
    clearLogo(item) {

      for (let i = 0; i < this.imgArr.length; i++) {
        if (this.imgArr[i].id == item.id) {
          this.imgArr[i].logoSrc = null;
          this.imgArr[i].avatar = "";
        }
      }
    },

    // 上传图片
    handleCoverSuccess(res, index) {
      if (res.code === 200) {
        this.imgArr[index].logoSrc = this.hostUrl + res.data.url; // 有域名
        this.imgArr[index].avatar = res.data.url; //无域名
        this.$message.success("图片上传成功");
      } else {
        this.$message.error("图片上传失败，请稍后再试~");
      }
    },
    beforeCoverUpload(file) {
      const isImg =
        file.name.toLowerCase().endsWith(".jpg") ||
        file.name.toLowerCase().endsWith(".jpeg") ||
        file.name.toLowerCase().endsWith(".png");
      if (!isImg) {
        this.$message.error("上传图片只能是 jpg 或 jpeg 或 png 格式!");
        return false;
      }
      const isSize = new Promise(function (resolve, reject) {
        let width = 800;
        let height = 800;
        let _URL = window.URL || window.webkitURL;
        let image = new Image();
        image.onload = function () {
          let valid = image.width == width && image.height == height;
          valid ? resolve() : reject();
        };
        image.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("上传头像图片尺寸不符合,只能是800*800!");
          return Promise.reject();
        }
      );
      return isImg&&isSize;
    },
    onStart() {
      this.drag = true;
    },
    onEnd() {
      this.drag = false;
    },
    //move回调方法
    onMove(e, originalEvent) {
      //第一个上传不允许停靠
      if (e.relatedContext.element.id == 0) return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  height: 100%;
  box-sizing: border-box;
  padding: 30px 30px 0 10px;
  .el-scrollbar {
    margin: 20px;
    height: calc(100% - 100px);
  }

  ::v-deep .el-scrollbar__wrap {
    height: 100%;
    overflow-x: hidden;

    .el-scrollbar__view {
      height: 100%;
    }
  }
}
.quality-course-content {
  flex: 1;
  // display: flex;
  overflow: hidden;
  margin-bottom: 37px;
  .content-item-left {
    * {
      box-sizing: border-box;
    }

    width: 60%;
    box-sizing: border-box;
  }
  .content-item-right {
    * {
      box-sizing: border-box;
    }
    min-width: 636px;
    width: 40%;
    box-sizing: border-box;
  }
  .content-left {
    height: 100%;
    background-color: #fff;
    overflow: auto;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;

      .el-scrollbar__view {
        padding: 0;
      }
    }
    .border {
      display: inline-block;
      width: 6px;
      height: 12px;
      margin-right: 10px;
      background-color: rgb(40, 33, 252);
      border-radius: 5px;
    }
    .el-input {
      width: 300px;
    }
    ::v-deep .el-input__inner {
      text-align: left;
    }
    .el-input-number {
      width: 300px;
    }
  }
  .content-right {
    height: 100%;
    background-color: #fff;
    overflow: auto;
    padding-left: 40px;
    margin-top: 20px;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .border {
      display: inline-block;
      width: 6px;
      height: 12px;
      margin-right: 10px;
      background-color: rgb(40, 33, 252);
      border-radius: 5px;
    }
    .cont {
      padding: 20px 55px;
      background-color: #f7f8fc;
    }
    .logo-src-box {
      display: flex;
      width: 80px;
      height: 80px;
      box-sizing: border-box;
      border: 1px solid #eeeeee;
      position: relative;
      img {
        max-width: 100%;
        max-height: 100%;
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border: 13px solid red;
        border-color: red red transparent transparent;
        i {
          position: absolute;
          right: -11px;
          top: -11px;
          font-size: 18px;
          line-height: 13px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .logo-uploader {
      ::v-deep.el-upload {
        width: 80px;
        height: 80px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo-uploader-icon {
          font-size: 20px;
          color: #a0a0a0;
        }
      }
    }
    .img-arr {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .log-img {
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(5) {
          margin-right: 0px;
        }
        &:nth-child(10) {
          margin-right: 0px;
        }
      }
    }
    .chosen {
      border: solid 2px #3089dc !important;
    }
    .imgName {
      text-align: center;
      color: #ababbb;
      padding-top: 10px;
    }
    .paster-left {
      margin-right: 40px;
    }
    .paster-right {
      padding-top: 20px;
    }
  }
}
.footer-button {
  text-align: center;
}
</style>
